// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import memoizeOne from 'memoize-one';
import React, { PureComponent } from 'react';
import type { IntlShape, WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import window from 'window-or-global';

import { DataSourceProvider as ReferentialityDataSourceProvider } from '@atlassian/editor-referentiality';

import type { DocNode } from '@atlaskit/adf-schema';
import { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { EmojiResource } from '@atlaskit/emoji/resource';
import { ReactRenderer as AkRenderer } from '@atlaskit/renderer';
import { SmartCardProvider } from '@atlaskit/link-provider';
import { EditorCardProvider } from '@atlaskit/editor-card-provider';
import type { ObjectKey, TaskState } from '@atlaskit/task-decision';
import type ProfileClient from '@atlaskit/profilecard/client';

import { ContentRefetchHandler } from '@confluence/content-body';
import { editorFeatureFlags } from '@confluence/editor-features/entry-points/editorFeatureFlags';
import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import { getSmartCardRenderers } from '@confluence/fabric-extension-lib';
import type { RendererExtensionHandlers } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { getSmartCardProviderFeatureFlags } from '@confluence/linking-platform';

import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import {
	ANALYTICS_EVENT_SEVERITY_TRACKING,
	createSmartCardEventHandler,
	registerGlobalMediaViewedListener,
	removeGlobalMediaViewedListener,
} from '@confluence/adf-renderer';
import { BannerStateContainer, FloatingBannerStateContainer } from '@confluence/banners';
import type { TaskAction, ProfileCardGetActions } from '@confluence/fabric-media-support';
import {
	createRendererMediaProvider,
	createRendererTemplateMediaProvider,
	getMediaFeatureFlags,
	createMentionsConfig,
	SetBatchedTaskStatusMutation,
	TaskBatcher,
	getProfileCardProvider,
	ConfluenceTaskDecisionProvider,
} from '@confluence/fabric-media-support';
import { getEmojiProvider } from '@confluence/fabric-providers';
import { getCanViewUserProfile } from '@confluence/global-operations';
import { inlineCommentStyles, WithAnnotationsWrapper } from '@confluence/content-renderer';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { getAtlassianPeopleProfileUrl } from '@confluence/profile';
import { getApolloClient } from '@confluence/graphql';
import type { RoutesContextType, WithNavigationPolicyProps } from '@confluence/route-manager';
import { withNavigationPolicy, withRoutesContext } from '@confluence/route-manager';

import type { CoreInvitesContextType } from '@confluence/core-invites-provider';
import { withCoreInvitesContext } from '@confluence/core-invites-provider';
import type { ExternalShareContextType } from '@confluence/external-share-context';
import { withExternalShareContext } from '@confluence/external-share-context';
import type {
	FeatureFlagsType,
	SessionDataContextInjected,
	SessionDataType,
} from '@confluence/session-data';
import { AccessStatus, withSessionData } from '@confluence/session-data';

import { FlagsStateContainer } from '@confluence/flags';
import { PageLoadMark } from '@confluence/browser-metrics';
import { RendererAnalyticsListener } from '@confluence/fabric-analytics-listeners';
import { getConnectDataListener } from '@confluence/app-referentiality/entry-points/connectDataListener';
import { expVal } from '@confluence/feature-experiments';

import { getExtensionHandlers } from '../../lib/renderer-extensions';

import { unsupportedContentLevelsTrackingOptions } from './fabric-feature-flags';
import type { ExternalProps } from './Renderer';
import { populateExtensionProviders } from './populateExtensionProviders';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- To migrate as part of go/ui-styling-standard
const StyledRenderer = styled.div<{
	intl: IntlShape;
}>`
	${({ intl }) => inlineCommentStyles(intl)}
`;

const _RERENDER_STATE_NONE = '';
const _RERENDER_STATE_REQUESTED = 'requested';

export type ComponentProps = ExternalProps;

type ProfilecardProvider = {
	cloudId: string;
	resourceClient: ProfileClient;
	getActions: ProfileCardGetActions;
};

type ComponentState = {
	_root: HTMLElement | undefined;
	rerender: string;
	taskBatcher: TaskBatcher | undefined;
	shouldRefetchContent: boolean;
};

type ExcerptSpecificProps = {
	mediaToken?: string;
};

class RendererComponent extends PureComponent<
	ComponentProps &
		WrappedComponentProps &
		RoutesContextType &
		WithNavigationPolicyProps &
		CoreInvitesContextType &
		ExternalShareContextType &
		SessionDataContextInjected & { offsetTop?: number } & ExcerptSpecificProps,
	ComponentState
> {
	state = {
		_root: undefined,
		rerender: _RERENDER_STATE_NONE,
		referentialityDataSourceProvider: new ReferentialityDataSourceProvider(),
		taskBatcher: undefined as TaskBatcher | undefined,
		shouldRefetchContent: false,
	};

	_handleActionToggle = (objectKey: ObjectKey, state: TaskState) => {
		// add the task action to be batched/updated in the batch before firing the request
		this.state.taskBatcher?.add({ objectKey, state });
	};

	_onBatchTaskUpdate = (batch: TaskAction[]) => {
		const { objectId, isPreviewMode } = this.props;

		const updateBatch = batch.map((update) => {
			const { objectKey, state } = update;
			const status = state === 'DONE' ? 'CHECKED' : 'UNCHECKED';

			return { taskId: objectKey.localId, status };
		});

		const taskBatch = {
			contentId: objectId,
			tasks: updateBatch,
			trigger: 'VIEW_PAGE',
		};

		if (!isPreviewMode) {
			getApolloClient()
				.mutate({
					mutation: SetBatchedTaskStatusMutation,
					variables: taskBatch,
				})
				.then(() => {
					this.setState({ shouldRefetchContent: true });
				})
				.catch((error: Error): any => {
					void getAnalyticsWebClient().then((client) => {
						client.sendOperationalEvent({
							source: 'page',
							action: 'batchTaskUpdate',
							actionSubject: 'checkbox',
							attributes: {
								error: error.message || String(error),
								stack: error.stack,
							},
						});
					});
				});
		}
	};

	_handleProfileCardActions: ProfileCardGetActions = (userId) => {
		const { push } = this.props;
		const atlassianPeopleProfileUrl = getAtlassianPeopleProfileUrl(userId, null, CONTEXT_PATH);
		return [
			{
				label: this.props.intl.formatMessage({ id: 'view.profile' }),
				link: atlassianPeopleProfileUrl,
				callback: () => {
					push(getAtlassianPeopleProfileUrl(userId, null, CONTEXT_PATH));
				},
			},
		];
	};

	componentDidMount() {
		const { adf, sessionData } = this.props;

		if (adf && sessionData?.isLicensed) {
			registerGlobalMediaViewedListener();
		}

		getConnectDataListener().init({
			emit: (localId, data) =>
				this.state.referentialityDataSourceProvider.createOrUpdate(localId, data),
		});
		const taskBatcher = new TaskBatcher(
			expVal('confluence_frontend_task_batching_interval', 'value', 3000) as number,
		);
		taskBatcher.onFlush(this._onBatchTaskUpdate);

		this.setState({ taskBatcher });
	}

	/**
	 * Needed to force an update of Atlaskit Renderer to wake-up TWP code inside that opens expand based on hash in the case where it hasn't changed.
	 * The alternative of using getDerivedStateFromProps required multiple steps which didn't work with RTL testing.
	 * Settled on normally discouraged setState inside of componentDidUpdate with an eslint-disable to get through Bamboo.
	 */
	/**
	 * When the objectId of the renderer changes, we want to reset the `shouldFetchContent` state variable so we don't
	 * cause unnecessary refetches; Calling setState behind a condition that will only happen on page transitions
	 */
	componentDidUpdate(prevProps) {
		const { objectId } = this.props;
		const { rerender } = this.state;
		if (rerender === _RERENDER_STATE_REQUESTED) {
			// eslint-disable-next-line
			this.setState({ rerender: _RERENDER_STATE_NONE });
		}

		if (objectId !== prevProps.objectId) {
			// eslint-disable-next-line
			this.setState({ shouldRefetchContent: false });
		}
	}

	componentWillUnmount() {
		const { adf, sessionData } = this.props;

		if (adf && sessionData?.isLicensed) {
			removeGlobalMediaViewedListener();
		}
	}

	getExpandHash = () => {
		if (this.state.rerender === _RERENDER_STATE_REQUESTED) {
			return '';
		} else {
			return this.props.getHash();
		}
	};

	getExtensionHandlers = (shouldIncludeMacroHandlers?: boolean): RendererExtensionHandlers => {
		const {
			adf,
			objectId,
			spaceKey,
			showTemplateVariablesInPreview,
			showTemplateInputInPreview,
			appearance,
			isPreviewMode = false,
		} = this.props;

		const { referentialityDataSourceProvider } = this.state;

		return getExtensionHandlers({
			adf,
			contentId: objectId,
			appearance: appearance as string,
			showTemplateVariablesInPreview,
			showTemplateInputInPreview,
			spaceKey,
			shouldIncludeMacroHandlers,
			shouldIncludeMacroWithReferencesHandlers: true,
			referentialityDataSourceProvider,
			fromFabricEditor: true,
			isPreviewMode,
		});
	};

	getEventHandlers = (): React.ComponentProps<typeof AkRenderer>['eventHandlers'] => {
		const { matchRoute, push } = this.props;

		return {
			...createSmartCardEventHandler({ matchRoute, push }),
		};
	};

	_setRoot = (ref) => this.setState({ _root: ref });

	/* getting type issues with ProfilecardProvider in atlaskit with ProfileCardProvider from next.
   This helps resolve the type errors */
	getProfilecardProvider = (
		cloudId: string,
		getActions: ProfileCardGetActions,
		featureFlags: FeatureFlagsType,
	): Promise<ProfilecardProvider> => {
		return getProfileCardProvider(cloudId, getActions, featureFlags);
	};

	getEditorFeatureFlags = memoizeOne(editorFeatureFlags);

	getProviders = memoizeOne((flags) => {
		const {
			intl,
			accountId,
			contentType,
			cloudId,
			environment,
			objectId,
			spaceKey,
			isExternalShareRequest,
			sessionData,
			showTemplateInputInPreview,
			showTemplateVariablesInPreview,
			openCoreInvites,
			userRole,
			mediaToken,
			isUsingPublicLinkInfo,
		} = this.props;

		const { featureFlags, activationId } = sessionData as SessionDataType;

		const isTemplateMedia = showTemplateVariablesInPreview || showTemplateInputInPreview;
		const mediaProvider = isTemplateMedia
			? createRendererTemplateMediaProvider({
					spaceKey,
					templateIds: [objectId],
				})
			: createRendererMediaProvider({
					contentId: objectId,
					isExternalShare: isExternalShareRequest,
					mediaToken,
					isUsingPublicLinkInfo,
				});
		const providerFactory = ProviderFactory.create({
			mediaProvider,
			taskDecisionProvider: Promise.resolve(
				new ConfluenceTaskDecisionProvider(intl, flags, objectId, this._handleActionToggle),
			),
			cardProvider: Promise.resolve(new EditorCardProvider(undefined, undefined, 'CONFLUENCE')),
		});

		if (cloudId && accountId) {
			providerFactory.setProvider(
				'mentionProvider',
				createMentionsConfig(cloudId, accountId, undefined, undefined, openCoreInvites, userRole)
					.then((config) => config.mentionResource)
					.catch((err) => {
						if (process.env.NODE_ENV !== 'production') {
							// eslint-disable-next-line no-console
							console.error('Failed to initialise mention resource ', err);
						}
						return Promise.reject();
					}),
			);
		}

		const hasAccess =
			sessionData?.accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
			sessionData?.accessStatus === AccessStatus.LICENSED_USE_ACCESS;

		if (cloudId) {
			providerFactory.setProvider(
				'emojiProvider',
				getEmojiProvider(EmojiResource, {
					cloudId,
					userId: accountId,
					allowAnonymousAccess: true,
				}),
			);
		}

		if (cloudId && hasAccess) {
			// If global view user profile is enabled then user can view profile card
			// Making promise void as in the worst case the
			void getCanViewUserProfile().then((hasViewUserProfilePermission) => {
				if (hasViewUserProfilePermission) {
					providerFactory.setProvider(
						'profilecardProvider',
						this.getProfilecardProvider(
							cloudId,
							this._handleProfileCardActions,
							featureFlags,
						) as any,
					);
				}
			});
		}

		if (isTemplateMedia) {
			const contextIdentifierProvider = async () => {
				const templateObjectId = JSON.stringify({
					templateId: objectId,
					spaceKey,
				});

				return Promise.resolve({
					containerId: spaceKey || '',
					objectId: templateObjectId,
					product: 'confluence',
				});
			};
			providerFactory.setProvider('contextIdentifierProvider', contextIdentifierProvider());
		} else if (spaceKey) {
			providerFactory.setProvider(
				'contextIdentifierProvider',
				Promise.resolve({
					containerId: spaceKey,
					objectId,
					product: 'confluence',
				}),
			);
		}

		populateExtensionProviders(providerFactory, {
			intl,
			contentId: objectId,
			containerId: spaceKey || '',
			accountId,
			cloudId,
			spaceKey,
			contentType,
			environment,
			featureFlags,
			extensionHandlers: this.getExtensionHandlers(true),
			showTemplateInputInPreview,
			showTemplateVariablesInPreview,
			activationId,
		});

		return providerFactory;
	});

	decodeHash = (hash) => {
		let decodedHash = '';
		try {
			// try to decode the hash
			decodedHash = decodeURIComponent(hash);
		} catch (e) {
			// if hash is not encoded, it will throw a URI Malformed error,
			// which we will then return the original hash
			decodedHash = hash;
		}
		return decodedHash;
	};

	handleClicksInRenderer = (event) => {
		/**
		 * Covering a corner case:
		 * If the user
		 * - has followed a deep link to a header inside an expand
		 * - then closes that expand
		 * - then clicks a TOC link to the same header
		 * the renderer code will not see a change in the deep link has, so it won't run which leaves the user scrolled
		 * to a closed expand element.
		 * This code detects the above situation and sets the rerender state property to begin a double re-render of the
		 * renderer (once with no hash and once with the original hash). This lets the renderer code see a change so it
		 * opens the expand.
		 */
		const deepLinkHash = event.target.hash;
		if (deepLinkHash === `#${this.getExpandHash()}`) {
			const tocTarget = document.getElementById(this.decodeHash(this.getExpandHash()));
			if (tocTarget) {
				const expandParent = tocTarget.closest("[data-node-type='expand']");
				// If the TOC link points to a header inside a closed expand
				if (expandParent && expandParent.getAttribute('data-expanded') === 'false') {
					this.setState({
						rerender: _RERENDER_STATE_REQUESTED,
					});
				}
			}
		}
	};

	renderRenderer = (extensionHandlers, dataProviders) => {
		const {
			adf,
			id,
			className,
			style,
			allowInlineComments,
			ignoreHeadingAnchorLinksFeatureFlag,
			renderWhenReady,
			onComplete,
			objectId,
			sessionData,
			allowPlaceholderText = false,
			annotationProvider,
			allowAnnotations = false,
			UNSTABLE_allowTableAlignment = true,
			UNSTABLE_allowTableResizing = true,
		} = this.props;

		const { shouldRefetchContent } = this.state;

		if (!adf) {
			return null;
		}

		// Allow disabling the feature for previews.
		const allowHeadingAnchorLinks = !ignoreHeadingAnchorLinksFeatureFlag;

		const { offsetTop, appearance } = this.props;
		const stickyHeaders = offsetTop !== undefined && {
			offsetTop,
		};

		const { featureFlagClient, featureFlags, isAdminHubAIEnabled } = sessionData as SessionDataType;

		const linkingPlatformFeatureFlags = getSmartCardProviderFeatureFlags(
			featureFlagClient,
			featureFlags,
			'renderer',
		);

		// used for renderer's annotation provider
		const localRef = React.createRef() as React.RefObject<HTMLDivElement>;

		return (
			<StyledRenderer
				id={id}
				intl={this.props.intl}
				data-inline-comments-target={allowInlineComments ? 'true' : 'false'}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={className}
				ref={this._setRoot}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ ...style }}
				data-test-appearance={this.props.appearance}
			>
				<SmartCardProvider
					{...getSmartCardRenderers()}
					featureFlags={linkingPlatformFeatureFlags}
					isAdminHubAIEnabled={isAdminHubAIEnabled}
					product="CONFLUENCE"
				>
					{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
					<div onClick={this.handleClicksInRenderer}>
						<RendererAnalyticsListener featureFlags={featureFlags} objectId={objectId}>
							{(appearance === 'full-page' || appearance === 'full-width') && (
								<PageLoadMark name="onBeforeAkRenderer" />
							)}
							{/**
							 * If you modify any of the AkRenderer props below, please also
							 * update `next/packages/adf-renderer/src/ADFRenderer.tsx`
							 * to maintain parity with the next version of the renderer.
							 */}
							<WithAnnotationsWrapper
								annotationProvider={annotationProvider}
								rendererRef={localRef}
								adfDocument={(this.props.navigationPolicy?.shimAdfUrls(adf) ?? adf) as DocNode}
								updateDocument={() => {}} /* unnecessary right now */
								isNestedRender
							>
								<AkRenderer
									appearance={this.props.appearance}
									document={(this.props.navigationPolicy?.shimAdfUrls(adf) ?? adf) as DocNode}
									adfStage="stage0"
									dataProviders={dataProviders}
									eventHandlers={this.getEventHandlers()}
									extensionHandlers={extensionHandlers}
									featureFlags={this.getEditorFeatureFlags(featureFlags)}
									portal={this.state._root}
									onComplete={onComplete}
									allowColumnSorting
									allowHeadingAnchorLinks={{
										allowNestedHeaderLinks: allowHeadingAnchorLinks,
										activeHeadingId: this.decodeHash(this.getExpandHash()),
									}}
									allowAltTextOnImages
									media={{
										allowLinking: true,
										allowCaptions: true,
										featureFlags: getMediaFeatureFlags(featureFlags),
									}}
									stickyHeaders={stickyHeaders}
									useSpecBasedValidator
									allowCopyToClipboard
									allowWrapCodeBlock
									analyticsEventSeverityTracking={ANALYTICS_EVENT_SEVERITY_TRACKING}
									allowSelectAllTrap
									unsupportedContentLevelsTracking={unsupportedContentLevelsTrackingOptions()}
									allowPlaceholderText={allowPlaceholderText}
									allowCustomPanels
									smartLinks={{ hideHoverPreview: false }}
									allowAnnotations={allowAnnotations}
									annotationProvider={annotationProvider}
									UNSTABLE_allowTableAlignment={UNSTABLE_allowTableAlignment}
									UNSTABLE_allowTableResizing={UNSTABLE_allowTableResizing}
								/>
							</WithAnnotationsWrapper>
						</RendererAnalyticsListener>
					</div>
				</SmartCardProvider>
				{renderWhenReady && renderWhenReady()}
				<ContentRefetchHandler contentId={objectId} shouldRefetch={shouldRefetchContent} />
			</StyledRenderer>
		);
	};

	render() {
		window.performance.mark('CFP-63.view.page.classic.renderer.rendered');

		return (
			<Subscribe to={[FlagsStateContainer]}>
				{(flags: FlagsStateContainer) => {
					const dataProviders = this.getProviders(flags);

					return this.renderRenderer(this.getExtensionHandlers(false), dataProviders);
				}}
			</Subscribe>
		);
	}
}

export const RendererComponentWithIntl = injectIntl(withNavigationPolicy(RendererComponent));

class RendererComponentWithBanner extends PureComponent<
	ComponentProps &
		RoutesContextType &
		CoreInvitesContextType &
		ExternalShareContextType &
		SessionDataContextInjected,
	ComponentState
> {
	render() {
		const { allowStickyHeaders } = this.props;

		if (allowStickyHeaders === false) {
			return <RendererComponentWithIntl {...this.props} />;
		}

		return (
			<Subscribe to={[BannerStateContainer, FloatingBannerStateContainer]}>
				{(bannerState: BannerStateContainer, floatingBanners: FloatingBannerStateContainer) => {
					const offsetTop = bannerState.getTotalHeight() + floatingBanners.getFloatingHeight();
					return <RendererComponentWithIntl offsetTop={offsetTop} {...this.props} />;
				}}
			</Subscribe>
		);
	}
}

export default withErrorBoundary({
	attribution: Attribution.PAGE_EXTENSIONS,
})(
	withCoreInvitesContext(
		withExternalShareContext(withSessionData(withRoutesContext(RendererComponentWithBanner))),
	),
);
