import type { FC } from 'react';
import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/standard-button';
import { N0, N400, N50A, N60A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { TEMPLATE_GALLERY } from '@confluence/named-routes';
import { getTemplateIdAttributesForAnalytics, getTemplateKey } from '@confluence/template-utils';

import { TemplateIcon } from '../TemplateIcon';

import type { TemplateInfoObject } from './types';

const i18n = defineMessages({
	viewButton: {
		id: 'template-card.powered-by-template-card.view-button',
		defaultMessage: 'View',
		description: 'label for the button to view a template',
	},
	authorLabel: {
		id: 'template-card.powered-by-template-card.author-label',
		defaultMessage: 'by {author}',
		description: 'label for author of the template',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ItemWrapper = styled.div({
	boxSizing: 'border-box',
	height: '100%',
	width: '320px',
	borderRadius: '3px',
	backgroundColor: token('elevation.surface.overlay', N0),
	padding: token('space.250', '20px'),
	margin: token('space.100', '8px'),
	cursor: 'auto',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	boxShadow: token('elevation.shadow.overlay', `0 8px 16px -4px ${N50A}, 0 0 1px ${N60A}`),
	transition: 'box-shadow 300ms',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapper = styled.div({
	width: '100%',
	marginBottom: token('space.150', '12px'),
	display: 'flex',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LogoAndAuthorWrapper = styled.div({
	height: '40px',
	display: 'flex',
	alignItems: 'flex-end',
	flex: 1,
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AuthorName = styled.span({
	marginLeft: token('space.100', '8px'),
	font: token('font.body.UNSAFE_small'),
	color: token('color.text.subtle', N400),
	display: '-webkit-box',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplateName = styled.span({
	font: token('font.heading.medium'),
	marginBottom: token('space.100', '8px'),

	display: '-webkit-box',
	'-webkit-line-clamp': '2',
	'-webkit-box-orient': 'vertical',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionWrapper = styled.div({
	font: token('font.body.UNSAFE_small'),
	marginBottom: token('space.150', '12px'),
	display: '-webkit-box',
	WebkitLineClamp: '3',
	WebkitBoxOrient: 'vertical',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewButtonWrapper = styled.div({
	alignSelf: 'flex-end',
});

type CardComponentProps = {
	template: TemplateInfoObject;
};

export const PoweredByTemplateCard: FC<CardComponentProps> = ({ template }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { name, author, spaceKey, blueprintModuleCompleteKey } = template;
	const isAuthorFieldEnabled = author && blueprintModuleCompleteKey;
	const templateGalleryURL = TEMPLATE_GALLERY.toUrl(
		{},
		{
			query: {
				space: spaceKey,
				template: getTemplateKey(template),
				flow: 'poweredBy',
			},
		},
	);

	const fireAnalytics = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'poweredBy',
				action: 'clicked',
				actionSubject: 'viewButton',
				actionSubjectId: 'poweredBy',
				attributes: {
					...getTemplateIdAttributesForAnalytics(template),
				},
			},
		}).fire();
	}, [createAnalyticsEvent, template]);

	return (
		<ItemWrapper>
			<HeaderWrapper>
				<LogoAndAuthorWrapper>
					<TemplateIcon template={template} size="large" />
					{isAuthorFieldEnabled && (
						<AuthorName>
							<FormattedMessage values={{ author }} {...i18n.authorLabel} />
						</AuthorName>
					)}
				</LogoAndAuthorWrapper>
			</HeaderWrapper>
			<TemplateName>{name}</TemplateName>
			<DescriptionWrapper>{template.description}</DescriptionWrapper>
			<ViewButtonWrapper>
				<Button appearance="primary" onClick={fireAnalytics} href={templateGalleryURL}>
					<FormattedMessage {...i18n.viewButton} />
				</Button>
			</ViewButtonWrapper>
		</ItemWrapper>
	);
};
